<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>
    <section class="body">
      <h2 class="section-title">{{ name }}</h2>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }} Table</h4>
              <div class="card-header-form">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search"
                    v-model="search"
                    label="Search"
                  />
                  <div class="input-group-btn">
                    <button class="btn btn-primary">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="card card-body">
                <div class="row">
                  <div class="col">
                    <div class="form-row">
                      <div class="col">
                        <div class="form-group">
                          <select
                            name=""
                            id=""
                            v-model="filter.department_id"
                            class="form-control"
                          >
                            <option value="">Select Department</option>
                            <option
                              :value="department.id"
                              v-for="department in departments"
                              :key="department.id"
                            >
                              {{ department.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <select
                            v-model="filter.venue_id"
                            class="form-control"
                          >
                            <option value="">Select Venue</option>
                            <option
                              :value="venue.id"
                              v-for="venue in venues"
                              :key="venue.id"
                            >
                              {{ venue.title }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <input
                            type="date"
                            class="form-control"
                            v-model="filter.date"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <select v-model="filter.status" class="form-control">
                            <option value="">Select Status</option>
                            <option
                              :value="name"
                              v-for="(s, name) in status"
                              :key="name"
                            >
                              {{ name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <button class="btn btn-primary w-100 mt-1" @click="getData">
                      Filter Order Department
                    </button>
                  </div>
                </div>
              </div>
              <v-progress-circular
                v-if="loading"
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
              <v-data-table
                :headers="headers"
                :items="order_department"
                item-key="id"
                :search="search"
                hide-default-footer
                :page.sync="page"
                :items-per-page="itemsPerPage"
                class="elevation-1"
                @page-count="pageCount = $event"
                v-else
              >
                <template v-slot:item="row">
                  <tr>
                    <td>
                      {{ row.item.name_product }}
                    </td>
                    <td>{{ row.item.name_department }}</td>
                    <td>{{ row.item.name_venue }}</td>
                    <td>{{ row.item.name_unit }}</td>
                    <td>{{ row.item.count }}</td>
                    <td>
                      <button
                        class="badge badge-success"
                        data-toggle="modal"
                        :data-target="`#exampleModal-` + row.item.id"
                      >
                        {{ row.item.status_name }}
                      </button>
                      <the-modal
                        :modal-title="row.item.name_product"
                        :modal-id="row.item.id"
                        :modal-status="row.item.status"
                        :modal-status_name="row.item.status_name"
                      ></the-modal>
                    </td>
                    <td>
                      <button
                        @click="deleteDepartmentOrder(row.item.id)"
                        class="badge badge-danger"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <div class="card-footer text-right">
              <nav class="d-inline-block">
                <ul class="pagination mb-0">
                  <v-pagination
                    v-model="page"
                    :length="lastPage"
                    :total-visible="itemsPerPage"
                    @input="getData"
                  ></v-pagination>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import axios from 'axios';
import swal from 'sweetalert';
import TheModal from './Modal.vue';
import {
  DepartmentRepository,
  DepartmentOrderRepository,
  VenueRepository,
} from '../../repositories/RepositoryFactory';

export default {
  name: 'DepartmentOrder',
  components: { TheModal },
  data() {
    return {
      search: '',
      page: 1,
      lastPage: 0,
      pageCount: 0,
      itemsPerPage: 10,
      name: 'Department Order',
      order_department: [],
      loading: false,
      filter: {
        department_id: '',
        venue_id: '',
        date: new Date().toISOString().slice(0, 10),
        status: '',
      },
      departments: [],
      venues: [],
      status: [],
    };
  },
  mounted() {
    this.getDepartment();
    this.getStatus();
    this.getVenue();
    this.getData();
  },
  methods: {
    deleteDepartmentOrder(id) {
      swal({
        title: 'Are you sure?',
        text: 'Once deleted, you will not be able to recover this imaginary file!',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios
            .delete(this.$store.state.api + 'order_department/' + id, {
              headers: { Authorization: this.$store.state.token },
            })
            .then(
              () =>
                (this.order_department = this.order_department.filter((v) => {
                  return v.id != id;
                }))
            )
            .catch((err) => {
              console.log(err);
              swal('Error!', err.message, 'error');
            });
          swal('Poof! Your product type file has been deleted!', {
            icon: 'success',
          });
        } else {
          swal('Your product type file is safe!');
        }
      });
    },
    getData() {
      this.loading = true;
      axios
        .get(this.$store.state.api + 'order_department', {
          headers: {
            Authorization: this.$store.state.token,
          },
          params: {
            page: this.page,
            department_id: this.filter.department_id,
            venue_id: this.filter.venue_id,
            date: this.filter.date,
            status: this.filter.status,
          },
        })
        .then((result) => {
          this.loading = false;
          this.order_department = result.data.data.data;
          this.itemsPerPage = result.data.data.per_page;
          this.lastPage = result.data.data.last_page;
        })
        .catch((err) => {
          swal('Error!', err.message, 'error');
        });
    },
    getDepartment() {
      DepartmentRepository.get().then((result) => {
        this.departments = result.data.data;
      });
    },
    getVenue() {
      VenueRepository.get().then((result) => {
        this.venues = result.data.data;
      });
    },
    getStatus() {
      DepartmentOrderRepository.getStatus().then((result) => {
        this.status = result.data;
      });
    },
  },
  computed: {
    headers() {
      return [
        {
          text: 'Name Product',
          value: 'name_product',
        },
        {
          text: 'Department',
          value: 'name_department',
        },
        {
          text: 'Venue',
          value: 'name_venue',
        },
        {
          text: 'Unit',
          value: 'unit',
        },
        {
          text: 'Qty',
          value: 'count',
        },
        {
          text: 'Status',
          value: 'status',
        },
        { text: 'Action' },
      ];
    },
  },
};
</script>
